@media (max-width: 550px) {
    .card {
        padding: 1rem !important;
    }
}

@media (max-width: 375px) {
    .card {
        padding-inline: 10px !important;
    }
}