// Here you can add other styles

$black: #000000;
$black-50: #d3e0e9;
$primary: #292961;
$tertiary: #95aac9 !default;
$green: #00e396;
$info: #69d3f8;
$white: #ffffff;
$alert: #ffc48b;
$warning: #f2545b;


$medium-emphasis-inverse:  rgba($black, 1) !default;

body {
	overflow-x: hidden;
}


.white {
	color: #fff !important;
}

.black {
	color: #000 !important;
}

.bg_white {
	background-color: #fff !important;
}

.bg_gray {
	background-color: #d7dbde !important;
}

.dropdown-item-block {
    display: block !important;
}

.custom-navgroup .nav-link {
	color: rgba($black, 1);
}

.custom-popover .popover-body {
	text-align: center;
}

.ball-marker {
	position: relative;
	display: inline-block;
	cursor: pointer;
	border: none;
	border-radius: 50%;
	height: 12px;
	width: 12px;
	padding: 0;
}

.ball-blue {
	background-color: rgb(0, 143, 251);
	color: rgb(0, 143, 251);
}

.ball-green {
	background-color: $green;
	color: rgb(0, 227, 150);
}

.ball-yellow {
	background-color: rgb(254, 176, 25);
	color: rgb(254, 176, 25);
}

.card {
	transition: all 0.3s ease-in-out;
}

.card:hover {
	box-shadow: 0 0 10px 2px rgba($color: $black, $alpha: 0.2);
}

.border-dotted {
	border-bottom: 3px dotted $black-50;
	margin-inline: 6px;
}

.Pendente {
	border: none;
	border-radius: 8px;
	background-color: $alert;
	$warning: #f2545b;
	font-size: 14px;
	text-align: center;
	padding: 4px 8px;
}

.Pago, .Carrinho {
	border: none;
	border-radius: 8px;
	background-color: $tertiary;
	font-size: 14px;
	text-align: center;
	padding: 4px 8px;
}

.Cancelado {
	border: none;
	border-radius: 8px;
	background-color: $warning;
	color: $white;
	font-size: 14px;
	text-align: center;
	padding: 4px 8px;
}

.row-hover:hover{
	background-color: rgba($secondary, 0.4);
}

.placeholder-white::-webkit-input-placeholder {
	color: $white;
}

.placeholder-white::-moz-placeholder {
	color: $white;
}

.placeholder-white::-ms-input-placeholder {
	color: $white;
}

.placeholder-white:-moz-placeholder {
	color: $white;
}

.timeline {
	border-radius: 50%;
	color: $white;
	height: 30px;
	width: 30px;
	padding: 0;
	margin-left: 10px;
}

.timelineGray {
	background: $tertiary;
}

.timelineInfo {
	background: $info;
}

.timelineGreen {
	background: $green;
}

.timelinePrimary {
	background: $primary;
}

.timeline::before{
    content: "";
    position: absolute;
	top: -16px;
	left: 36px;
	height: 18px;
	width: 1px;
	background-color: $tertiary;
}

.timeline::after{
	content: "";
	position: absolute;
	top: 30px;
	left: 36px;
	height: 18px;
	width: 1px;
	background-color: $tertiary;
}

.w-fitContent, .w-fitContent > label {
	width: fit-content;
	white-space: nowrap;
}

.w-fitContent > label {
	border-radius: 40px;
}

.h-fitContent {
	height: fit-content;
}

.px-0-sm {
	padding-left: 0 !important;
	padding-right: 0 !important;
}

.justify-center-sm {
	@media (max-width: 576px) {
		justify-content: center !important;
	}
}

.justify-center-md {
	@media (max-width: 768px) {
		justify-content: center !important;
	}
}

#dropdown {

}

#dropdown > button {
	background: transparent;
	border: none;
	padding: 0;
}

.dropdown-toggle::after {
	display: none !important;
}

fieldset {
	border: 1px solid #d8dbe0 !important;
    padding:1px !important;
}

legend {
    padding: 1px 6px !important;
    float: none !important;
    width: auto;
}

.blueCard {
	background: linear-gradient(-90deg, rgb(18, 188, 217) 0%, rgb(3, 124, 227) 100%);
	border: none;
	box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
	position: relative;
	transform: scale(1);
	transition: all 0.4s;
}

.orangeCard {
	background: linear-gradient(-90deg, rgba(254,153,0,1) 0%, rgba(227,138,3,1) 100%);
	border: none;
	box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
	position: relative;
	transform: scale(1);
	transition: all 0.4s;
}

.greenCard {
	background: linear-gradient(-90deg, rgba(69,160,80,1) 0%, rgba(22,173,40,1) 100%);
	border: none;
	box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
	position: relative;
	transform: scale(1);
	transition: all 0.4s;
}

.orangeCard:hover, .greenCard:hover, .blueCard:hover {
	box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    opacity: 1;
    color: #2a3e4c;
    transform: scale(1.02)
}

.line {
	border: 0;
	border-bottom: 3px solid #ffffff21;
	border-radius: 50%;
	margin-top: 1rem;
	margin-bottom: 0.5rem;
}

.card-circle-top {
	position: absolute;
    height: 50%;
    width: 100%;
    padding: 15px;
    top: -40px;
    right: -15px;
    background-color: rgba(255, 255, 255, 0.15);
    border-radius: 50%;
}

.card-circle-right {
	position: absolute;
    height: 100%;
    width: 25%;
    padding: 15px;
    top: -25px;
    right: -13px;
    background-color: rgba(255, 255, 255, 0.15);
    border-radius: 50%;
}

.image-wrapper {
	position: relative;
	transition: all 0.5s;
}


.icon-wrapper {
	position: absolute;
	top: 6px;
	left: 30px;
	display: none;
	font-size: 34px;
	transition: all 0.5s;
}

.image-wrapper:hover {
	background-color: rgba($black, 0.3);
	border-radius: 0.25rem;

	.icon-wrapper {
		display: block;
	}
}

.radio_check {
	width: fit-content;
}